import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import SocialReviewsFullwidth from '../components/social-reviews-fullwidth';
import { TestimonialVideo } from '../components/testimonial_video';
import { StartWithAFreeQuote } from '../components/start-with-a-free-quote';
import Breadcrumbs from '../components/breadcrumbs';

const BlogPostsLayoutContainer = styled.div`
	padding: 2em 1em;
`;

const Heading = styled.div`
	padding: 3em 1em;
	padding-bottom: 5em;
	text-align: center;
	margin-bottom: -5em;

	h1 {
		font-size: calc(1.15vw + 1.25em);
		margin-bottom: 0.15em;
	}

	p {
		color: #666;
		margin-bottom: 0.15em;
	}
`;

const TestimonialsPage = props => {
	const data = useStaticQuery(graphql`
		query getAllTestimonialVideos {
			allContentfulTestimonialVideoPost {
				edges {
					node {
						id
						slug
						createdAt
						category {
							name
							slug
						}
						video {
							id
							title
							file {
								url
								contentType
							}
						}
						projectReference {
							slug
						}
						thumbnail {
							id
							file {
								url
							}
							gatsbyImageData(
								width: 600
								quality: 90
								placeholder: NONE
							)
						}
					}
				}
			}
			contactFormSideImage: contentfulAsset(
				title: { eq: "Bathroom Remodeling New Jersey" }
			) {
				id
				title
				gatsbyImageData
			}
			allContentfulTestimonialCategory {
				edges {
					node {
						id
						name
						slug
					}
				}
			}
		}
	`);

	return (
		<Layout pageProps={props}>
			<div className="border-b border-gray-100">
				<nav
					className="flex container mx-auto px-4 lg:px-8 py-3 max-w-7xl"
					aria-label="Breadcrumb"
				>
					<Breadcrumbs
						links={[
							{ to: '/', label: 'Home' },
							{
								to: props.location.pathname,
								label: 'Testimonials'
							}
						]}
					/>
				</nav>
			</div>
			<SEO
				title={'Testimonials | Bay Area Siding Company'}
				description={
					'Read some testimonials and reviews about Bay Area Siding Company'
				}
				meta={[{ name: 'robots', content: 'noindex' }]}
			/>
			<Heading style={{ paddingTop: '1em' }}>
				<h1>Project Videos</h1>
				<p>Check out some of our recently completed projects.</p>
			</Heading>
			<BlogPostsLayoutContainer className="container max-w-7xl mx-auto pt-2 grid grid-cols-12 gap-x-2">
				{data.allContentfulTestimonialVideoPost.edges.map(
					({ node: testimonialVideo }) => (
						<TestimonialVideo {...testimonialVideo} showTitle />
					)
				)}
			</BlogPostsLayoutContainer>
			<StartWithAFreeQuote {...props} data={data} />
			<SocialReviewsFullwidth modalLinkTo={`/reviews/all/`} />
		</Layout>
	);
};

export default TestimonialsPage;
